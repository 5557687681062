@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Short Stack';
    src: url('https://fonts.gstatic.com/s/shortstack/v10/bMrzmS2X6p0jZC6EcmPFX-SScX8D0nq6.ttf');
}

html {
    @apply font-ShortStack;
}

button {
    @apply border-black border-[2px] rounded-md p-5 hover:bg-black hover:text-white duration-100 uppercase;
}

input[type='checkbox'] {
    @apply accent-black;
}
